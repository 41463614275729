<template>
  <div
    class="
      flex flex-col
      md:flex-row
      mt-8
      md:mt-0 md:min-h-screen
      ml-1
      mr-1
      md:ml-12 md:mr-12
      overflow-x-hidden overflow-y-hidden
    "
  >
    <div
      class="
        flex-0
        md:flex
        content-center
        w-full
        md:w-6/12
        min-h-0
        md:min-h-screen
        h-screen
        bg-contain bg-center bg-app-logo bg-no-repeat
        hidden
      "
    >
      <div class="flex flex-col justify-center content-center pl-16 pr-8">
        <registration-item
          :title="$t('auth.register.strength_1_title')"
          :desc="$t('auth.register.strength_1_desc')"
        />

        <registration-item
          class="mt-10"
          :title="$t('auth.register.strength_2_title')"
          :desc="$t('auth.register.strength_2_desc')"
        />

        <registration-item
          class="mt-10"
          :title="$t('auth.register.strength_3_title')"
          :desc="$t('auth.register.strength_3_desc')"
        />
      </div>
    </div>
    <div
      class="
        flex flex-0 flex-col
        md:flex-none
        w-full
        md:w-6/12 md:pr-10
        mb-4
        md:mb-0
        lg:mt-14
        p-6
        bg-contain bg-center bg-app-logo bg-no-repeat
        md:bg-none
        xl:h-auto
        justify-center
        md:justify-start
      "
    >
      <div
        class="w-full bg-white rounded-xl shadow-lg max-w-lg md:mb-4 lg:mb-0"
      >
        <form class="mb-0 space-y-6 w-full p-4 md:p-14 md:pb-12 pt-4">
          <app-logo class="mt-0" imgclass="w-24 h-auto" />
          <app-form-input
            id="email"
            name="email"
            type="email"
            autocomplete="email"
            :labelText="$t('general.email')"
            :required="true"
            :loading="loading"
            v-model="user.email"
            :errorMessage="errors.email"
          />
          <app-form-input
            id="fullname"
            name="fullname"
            type="text"
            autocomplete="name"
            :labelText="$t('general.full_name')"
            class="mt-6"
            :required="true"
            :loading="loading"
            v-model="user.name"
            :errorMessage="errors.name"
          />

          <app-form-telephone-no
            name="phone_no"
            :labelText="$t('general.phone_no')"
            class="w-full mb-5"
            v-model="user.phone_no"
            :errorMessage="errors.phone_no"
          />

          <app-form-country
            :allowFilter="true"
            :showIcon="true"
            :labelText="$t('general.country')"
            class="w-full mb-5"
            v-model="user.country"
            :errorMessage="errors.country_id"
          />

          <app-form-password-input
            id="password"
            name="password"
            type="password"
            autocomplete="password"
            :labelText="$t('general.password')"
            class="mt-2"
            :required="true"
            :passwordstrength="true"
            :loading="loading"
            v-model="user.password"
            v-on:passwordValidated="onPasswordValidated"
            :errorMessage="errors.password"
            :showError="showPasswordError || !passwordValidated"
          />
          <div class="flex flex-row">
            <app-form-checkbox
              v-model="user.user_agreement"
              :errorMessage="errors.user_agreement"
            >
              <span class="text-sm text-gray-700">{{
                $t("auth.register.agreement")
              }}</span>
              <span class="text-sm">&nbsp;</span>
              <a
                class="text-bluelink text-sm"
                href="https://drive.google.com/file/d/1jrOl4mB6Qf3v3jrqBDBVoSaX-7ynUC3R/view?usp=share_link"
                target="_blank"
                >{{ $t("general.term_and_conditions") }}</a
              >
            </app-form-checkbox>
          </div>
          <app-alert-error :message="errorMessage" />
          <app-button
            :loading="loading"
            @onClick="registrationSubmit"
            eventName="register"
          >
            {{ $t("auth.register.create_account") }}
          </app-button>
          <div class="flex justify-center w-full text-sm">
            {{ $t("auth.register.already_have_account") }}
            <span class="text-sm">&nbsp;</span>
            <a class="text-primary cursor-pointer" @click="backToSignIn">{{
              $t("general.sign_in")
            }}</a>
          </div>
        </form>
      </div>
      <app-footer class="mt-14 max-w-lg" />
    </div>
  </div>
</template>

<script>
import RegistrationItem from "./items/registration-item.vue";

export default {
  data() {
    return {
      emptyErrors: {
        email: [],
        country_id: [],
        name: [],
        phone_no: [],
        password: [],
        accept_marketing_email: [],
      },
      recaptcha: Object,
      errorMessage: "",
      showPasswordError: false,
    };
  },

  components: {
    RegistrationItem,
  },
  computed: {
    loading() {
      return this.$store.getters["authStore/loading"];
    },

    errors() {
      return this.$store.getters["authStore/errors"] ?? this.emptyErrors;
    },

    user() {
      return this.$store.getters["authStore/user"];
    },

    passwordValidated() {
      return this.$store.getters["authStore/passwordValidated"];
    },
  },
  async mounted() {
    await this.$recaptchaLoaded();
    this.recaptcha = this.$recaptchaInstance;
    this.recaptcha.showBadge();
  },
  methods: {
    onPasswordValidated(validated) {
      this.$store.commit("authStore/setPasswordValidated", validated);
    },

    backToSignIn() {
      this.recaptcha.hideBadge();
      this.$router.push({ name: "login" });
    },
    async registrationSubmit() {
      this.resetState();

      await this.$recaptcha("register");

      var submitUser = {
        email: this.user.email,
        name: this.user.name,
        phone_no: this.user.phone_no,
        country_id: this.user.country.id,
        password: this.user.password,
        user_agreement: this.user.user_agreement,
      };

      const dispatchRegister = await this.$store.dispatch(
        "authStore/register",
        submitUser
      );

      if (dispatchRegister == null) {
        return;
      }

      this.recaptcha.hideBadge();
      this.$router.push(this.$route.redirectedFrom || { path: "/" });
    },

    resetState() {
      this.showPasswordError = false;
      this.$store.commit("authStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
  },
};
</script>
